const data = [
    {
      title: "nft",
      url: "#",
    },
    {
      title: "crypto",
      url: "#",
    },
    {
      title: "marketplace",
      url: "#",
    },
    {
      title: "trade",
      url: "#",
    },
    {
      title: "Lunchpad",
      url: "#",
    },
    {
      title: "ido",
      url: "#",
    },
    {
      title: "web 3.0",
      url: "#",
    },
  ]

  export default data;