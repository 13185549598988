import authorIcon1 from "../../images/blog/icon-img.png"
import authorIcon2 from "../../images/blog/icon-img2.png"
import authorIcon3 from "../../images/blog/icon-img3.png"

const data = [
     {
         thumb: authorIcon1,
         author: "Lisa Harrih",
         timeStamp: "12 FEB, 2022",
         text: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",

         replys: [
             {
                thumb: authorIcon2,
                author: "Morlex Deo",
                timeStamp: "12 FEB, 2022",
                text: "You need to be sure there isn't anything embarrassing hidden."
             }
         ]
     },
     {
         thumb: authorIcon3,
         author: "Boardman",
         timeStamp: "12 FEB, 2022",
         text: "Injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing",
     },
  ]

  export default data;