const data = [ 
    {
      widgetName: "Site map",
      items: [
        {
          title: "Home",
          url: "/",
        },
        {
          title: "About",
          url: "/#about",
        },
        {
          title: "Roadmap",
          url: "/#roadmap",
        },
        {
          title: "FAQ",
          url: "/#faq",
        },
        {
          title: "Articles",
          url: "#",
        }
      ],
    },
    {
      widgetName: "Site map",
      items: [
        {
          title: "Whitepaper",
          url: "https://docs.f-nft.us/f-nft-project/",
        },
        {
          title: "Terms Canditions",
          url: "#",
        },
        {
          title: "Privacy Policy",
          url: "#",
        },
      ],
    },

]

export default data;
