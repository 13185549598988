import clientThumb1 from "../../assets/images/partners/1.png";
import clientThumb2 from "../../assets/images/partners/2.png";
import clientThumb3 from "../../assets/images/partners/3.png";
import clientThumb4 from "../../assets/images/partners/4.png";
import clientThumb5 from "../../assets/images/partners/5.png";
import clientThumb6 from "../../assets/images/partners/6.png";
import clientThumb7 from "../../assets/images/partners/7.png";
import clientThumb8 from "../../assets/images/partners/8.png";

const data = [
  clientThumb1,
  clientThumb2,
  clientThumb3,
  clientThumb4,
  clientThumb5,
  clientThumb6,
  clientThumb7,
  clientThumb8,
];

export default data;
