// import { createAlchemyWeb3 } from "@alch/alchemy-web3";
export const bscscanapikey = "46Y2MZHAZTE34SD1WQ32BUF42BTDYBY76A";
export const moralisapikey = "1ByvMyujsaXkDVTlnUjQIje5e09J2zLHGaS2P6JytHVA1LxfAPPYE8UdOpEjc6ca";
export const BSCNFTCONTRACT = "0xeB63891bdEE6894E5eA3c6BCa8D197CC81d76Bf0";
export const BSCTESCONTRACT = '0xc1f32ee1634c4a3d217920122216aedbd1014f08';
export const STAKINGCONTRACT = "";
export const bsctest = "";
export const bscscanapi = "https://api.bscscan.com/api";
export const moralisapi = "https://deep-index.moralis.io/api/v2";
// export const Web3Alc = createAlchemyWeb3("https://polygon-mainnet.g.alchemy.com/v2/qqfXh-S-3dEdCR-orpw_NY06qvD0EFKk");
export const nftpng = "https://bafybeie2g4gdq5y2yafuemjteuhpzlzan3g7tu65625zt2j3nyndk7hao4.ipfs.nftstorage.link/Fantasy%20%23";