import priceThumb1 from "../../price/39.gif";
import priceThumb2 from "../../price/69.gif";
import priceThumb3 from "../../price/239.gif";
import priceThumb4 from "../../price/799.gif";
import priceThumb5 from "../../price/1999.gif";

const priceData = [
  {
    thumb: priceThumb1,
  },
  {
    thumb: priceThumb2,
  },
  {
    thumb: priceThumb3,
  },
  {
    thumb: priceThumb4,
  },
  {
    thumb: priceThumb5,
  },
];

export default priceData;
